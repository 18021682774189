import React, { useState } from "react";
import Editor from "react-simple-code-editor";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism.css"; //Example style, you can use another
import PropTyeps from "prop-types";
import { Card } from "reactstrap";
import theme from "prism-react-renderer/themes/nightOwl";

function CodeEditor({ code }) {
  const [preveiw, setCode] = useState(code);

  return (
    <Card>
      <Editor
        value={preveiw}
        onValueChange={(code) => setCode(code)}
        highlight={(code) => highlight(code, languages.js)}
        padding={10}
        style={{
          fontFamily: '"Fira code", "Fira Mono", monospace',
          fontSize: 12,
          ...theme.plain,
        }}
        className="h-100 w-100 overflow overflow-y"
      />
    </Card>
  );
}

export default CodeEditor;

CodeEditor.propTyeps = {
  code: PropTyeps.string,
};
