import React, { useState } from "react";
import { Card, CardBody } from "reactstrap";

function Counter() {
  const [counter, setCounter] = useState(0);

  return (
    <Card className=" ">
      <CardBody className="text-start ">
        Example :{" "}
        <div className="counter mt-2 p-2">
          <label id="countlabel">{counter}</label>
          <div className="counter__btn">
            <button
              id="decrease__btn"
              onClick={() => counter > 0 && setCounter(counter - 1)}
            >
              -
            </button>
            <button id="reset__btn" onClick={() => setCounter(0)}>
              reset
            </button>
            <button id="increase__btn" onClick={() => setCounter(counter + 1)}>
              +
            </button>
          </div>
        </div>
      </CardBody>
    </Card>
  );
}

export default Counter;

export const counterCode = `import React, { useState } from "react";
import { Card, CardBody } from "reactstrap";

function Counter() {
  const [counter, setCounter] = useState(0);

  return (
    <Card className=" ">
      <CardBody className="text-start ">
        Example :{" "}
        <div className="counter mt-2 p-2">
          <label id="countlabel">{counter}</label>
          <div className="counter__btn">
            <button
              id="decrease__btn"
              onClick={() => counter > 0 && setCounter(counter - 1)}
            >
              -
            </button>
            <button id="reset__btn" onClick={() => setCounter(0)}>
              reset
            </button>
            <button id="increase__btn" onClick={() => setCounter(counter + 1)}>
              +
            </button>
          </div>
        </div>
      </CardBody>
    </Card>
  );
}
`;
export const counterCss = `
:root {
  --body-color: #071324;
  --extra--color: #121b29;
  --main-color: #60f1d0;
  --item-color: #dff6ff;
}

.counter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  transition: all 0.5s ease;
  background: #0a192f;
  border: 3px solid #0a192f;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.05);
}

.counter label {
  margin-bottom: 50px;
  color: var(--item-color);
  font-size: calc(70 / 16 * 1rem);
}
.counter__btn{
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width:768px) {
  .counter__btn{
    flex-direction: column;
  }
}
.counter__btn button {
  padding: 10px 30px;
  margin: 0 20px 10px 20px;
  cursor: pointer;
  transition: all 0.5s ease;
}

.counter__btn button i {
  transition: all 0.7s ease;
}

.counter__btn #decrease__btn {
  color: var(--extra--color);
  background: var(--main-color);
  border: none;
  border-radius: 5px;
  font-size: calc(20 / 16 * 1rem);
  font-weight: 700;
}

.counter__btn #reset__btn {
  color: var(--main-color);
  background: none;
  border: 2.5px solid var(--main-color);
  border-radius: 5px;
  font-size: calc(19 / 16 * 1rem);
  font-weight: 700;
}

.counter__btn #increase__btn {
  color: var(--extra--color);
  background: var(--main-color);
  border: none;
  border-radius: 5px;
  font-size: calc(19 / 16 * 1rem);
  font-weight: 700;
}

.counter__btn #decrease__btn:hover,
.counter__btn #increase__btn:hover {
  background: #60f1cfd3;
}
`;
