import React from "react";
import { NavLink } from "reactstrap";

function SideBar() {
  return (
    <div className="p-4 pt-5 mt-2 mainSaidBar">
      <h5>Index</h5>
      <ol type="1" className="text-start">
        <li>
          <NavLink href="#whatIsReact">What is react</NavLink>
        </li>
        <li>
          {" "}
          <NavLink href="#getStarted">Get Started </NavLink>
        </li>
        <li>
          {" "}
          <NavLink href="#jsx">Introducing JSX</NavLink>
        </li>
        <li>
          {" "}
          <NavLink href="#rendering">Rendering</NavLink>
        </li>
        <li>
          <NavLink href="#createApp"> Let's Create a App </NavLink>
        </li>
        <li>
          <NavLink href="#aComponent">A Simple Component </NavLink>
        </li>
        <li>
          <NavLink href="#props">Component props </NavLink>
        </li>
        <li>
          <NavLink href="#howToAddAPage">How To add a Page in React </NavLink>
        </li>
        <li>
          <NavLink href="#howToMake404">How To Make a 404 page </NavLink>
        </li>
        <li>
          <NavLink href="#howToNavigate">
            How To Navigate Between Pages{" "}
          </NavLink>
        </li>
        <li>
          <NavLink href="#commonlyUsedReactHooks">
            Commonly Used React Hooks
          </NavLink>
        </li>
        <li>
          <NavLink href="#useEffect">useEffect</NavLink>
        </li>
        <li>
          <NavLink href="#useNavigate">useNavigate</NavLink>
        </li>
        <li>
          <NavLink href="#useLocation">useLocation</NavLink>
        </li>
        <li>
          <NavLink href="#useReducer">useReducer</NavLink>
        </li>
        <li>
          <NavLink href="#LearnMoreAboutReactHooks">
            Learn more about React hooks
          </NavLink>
          {/* <a
            target="_blank"
            href="https://www.youtube.com/watch?v=LlvBzyy-558&t=2201s"
          >
            Learn more about React hooks
          </a> */}
        </li>
        <li>
          <NavLink href="#ProjectOne">Projects 🖥 1 </NavLink>
        </li>
        <li>
          <NavLink href="#todo">make a todo app </NavLink>
        </li>
        <li>
          <NavLink href="#calculator">calculator </NavLink>
        </li>
        <li>
          <NavLink href="#disney">Disney+</NavLink>
        </li>

        <li>
          <NavLink href="#ContextApi">context api (state provider)</NavLink>
        </li>
        <li>
          <NavLink href="#redux">redux state management</NavLink>
        </li>
        <li>
          <NavLink href="#reduxeg">redux basic example</NavLink>
        </li>

        <li>
          <NavLink href="#finalProject">
            Final Project -<br /> Create a Dashboard
          </NavLink>
        </li>
        <li>
          <NavLink href="#git">Basic GIT</NavLink>
        </li>
      </ol>
    </div>
  );
}

export default SideBar;
