import React, { useState } from "react";
import { Card, CardBody, Input } from "reactstrap";

function TodoApp() {
  const [taskType, setTaskType] = useState("all"); //which task is showing
  const [inputValue, setInputValue] = useState(""); //get input value and save here
  const [tasks, setTasks] = useState([]); //save all task here {id:"", task:"", isChecked:fasle }

  const handleSubmitTask = (e) => {
    e.preventDefault();
    if (inputValue?.length > 0) {
      setTasks([
        ...tasks,
        { id: tasks?.length + 1, task: inputValue, isChecked: false },
      ]);
    }
    setInputValue("");
  };

  const handleDelete = (id) => {
    setTasks(tasks?.filter((task) => task.id !== id));
  };

  const handleCheckTask = (e, task) => {
    const modifiedTask = tasks?.map((item) =>
      item.id === task.id ? { ...task, isChecked: e.target.checked } : item
    );
    setTasks(modifiedTask);
  };

  const handleTaskView = () => {
    if (taskType === "all") return tasks;
    if (taskType === "pending")
      return tasks?.filter((task) => task.isChecked === false);
    if (taskType === "completed")
      return tasks?.filter((task) => task.isChecked === true);
  };

  return (
    <Card className="bg-black ">
      <CardBody className="text-start ">
        <div className="wrapper">
          <form onSubmit={handleSubmitTask}>
            <div className="task-input">
              <span className="pen">🖊</span>
              <input
                type="text"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                placeholder="Add a New Task + Enter"
              />
            </div>
          </form>
          <div className="controls">
            <div className="filters">
              <span
                className="active"
                onClick={(e) => setTaskType(e.target.id)}
                id="all"
              >
                All
              </span>
              <span id="pending" onClick={(e) => setTaskType(e.target.id)}>
                Pending
              </span>
              <span id="completed" onClick={(e) => setTaskType(e.target.id)}>
                Completed
              </span>
            </div>
            <button
              className={`clear-btn ${tasks?.length > 0 ? "active" : ""}`}
              onClick={() => setTasks([])}
            >
              Clear All
            </button>
          </div>
          {handleTaskView()?.length > 0 ? (
            <ul className={`task-box ${tasks?.length > 6 ? "overflow" : ""}`}>
              {handleTaskView()?.map((task) => (
                <li className="task text-start" key={task?.id}>
                  <Input
                    type="checkbox"
                    checked={task?.isChecked}
                    onChange={(e) => handleCheckTask(e, task)}
                  />
                  <label style={{ flex: 1 }}>
                    {task?.isChecked ? (
                      <del className="mb-0 pb-0 mx-3">{task?.task}</del>
                    ) : (
                      <p className="mb-0 pb-0">{task?.task}</p>
                    )}
                  </label>
                  <div
                    className="delete"
                    onClick={() => handleDelete(task?.id)}
                  >
                    Delete
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <p className="mx-4 mt-3 text-warning">
              You don't have a tasks yet!
            </p>
          )}
        </div>
      </CardBody>
    </Card>
  );
}

export default TodoApp;

export const TodoAppCode = `import React, { useState } from "react";
import { Card, CardBody, Input } from "reactstrap";

function TodoApp() {
  const [taskType, setTaskType] = useState("all"); //which task is showing
  const [inputValue, setInputValue] = useState(""); //get input value and save here
  const [tasks, setTasks] = useState([]); //save all task here {id:"", task:"", isChecked:fasle }

  const handleSubmitTask = (e) => {
    e.preventDefault();
    if (inputValue?.length > 0) {
      setTasks([
        ...tasks,
        { id: tasks?.length + 1, task: inputValue, isChecked: false },
      ]);
    }
    setInputValue("");
  };

  const handleDelete = (id) => {
    setTasks(tasks?.filter((task) => task.id !== id));
  };

  const handleCheckTask = (e, task) => {
    const modifiedTask = tasks?.map((item) =>
      item.id === task.id ? { ...task, isChecked: e.target.checked } : item
    );
    setTasks(modifiedTask);
  };

  const handleTaskView = () => {
    if (taskType === "all") return tasks;
    if (taskType === "pending")
      return tasks?.filter((task) => task.isChecked === false);
    if (taskType === "completed")
      return tasks?.filter((task) => task.isChecked === true);
  };

  return (
    <Card className="bg-black ">
      <CardBody className="text-start ">
        <div className="wrapper">
          <form onSubmit={handleSubmitTask}>
            <div className="task-input">
              <span className="pen">🖊</span>
              <input
                type="text"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                placeholder="Add a New Task + Enter"
              />
            </div>
          </form>
          <div className="controls">
            <div className="filters">
              <span
                className="active"
                onClick={(e) => setTaskType(e.target.id)}
                id="all"
              >
                All
              </span>
              <span id="pending" onClick={(e) => setTaskType(e.target.id)}>
                Pending
              </span>
              <span id="completed" onClick={(e) => setTaskType(e.target.id)}>
                Completed
              </span>
            </div>
            <button
              className={${`clear-btn ${`tasks?.length > 0 ? "active" : ""`}`}}
              onClick={() => setTasks([])}
            >
              Clear All
            </button>
          </div>
          {handleTaskView()?.length > 0 ? (
            
            <ul className={${`task-box ${`tasks?.length > 6 ? "overflow" : ""`}}`}>
              {handleTaskView()?.map((task) => (
                <li className="task text-start" key={task?.id}>
                  <Input
                    type="checkbox"
                    checked={task?.isChecked}
                    onChange={(e) => handleCheckTask(e, task)}
                  />
                  <label style={{ flex: 1 }}>
                    {task?.isChecked ? (
                      <del className="mb-0 pb-0 mx-3">{task?.task}</del>
                    ) : (
                      <p className="mb-0 pb-0">{task?.task}</p>
                    )}
                  </label>
                  <div
                    className="delete"
                    onClick={() => handleDelete(task?.id)}
                  >
                    Delete
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <p className="mx-4 mt-3 text-warning">
              You don't have a tasks yet!
            </p>
          )}
        </div>
      </CardBody>
    </Card>
  );
}

export default TodoApp;
`;

export const TodoAppCss = `

.wrapper {
    max-width: 405px;
    background: #fff;
    margin: 3rem auto;
    border-radius: 7px;
    padding: 28px 0 30px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  }
  .task-input {
    position: relative;
    height: 52px;
    padding: 0 25px;
  }
  
  .task-input .pen {
    position: absolute;
    top: 50%;
    color: #999;
    font-size: 25px;
    transform: translate(17px, -50%);
  }
  .task-input .plus {
    position: absolute;
    top: 50%;
    color: #999;
    font-size: 40px;
    transform: translate(-35px, -50%);
    cursor: pointer;
  }
  .delete{
    cursor: pointer;
  }
  
  .task-input input {
    height: 100%;
    width: 100%;
    outline: none;
    font-size: 18px;
    border-radius: 5px;
    padding: 0 20px 0 53px;
    border: 1px solid #999;
  }
  
  .task-input input:focus,
  .task-input input.active {
    padding-left: 52px;
    border: 2px solid #f12711;
  }
  
  .task-input input::placeholder {
    color: #bfbfbf;
  }
  
  .controls,.wrapper li {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .controls {
    padding: 18px 25px;
    border-bottom: 1px solid #ccc;
  }
  
  .filters span {
    margin: 0 8px;
    font-size: 17px;
    color: #444444;
    cursor: pointer;
  }
  
  .filters span:first-child {
    margin-left: 0;
  }
  
  .filters span.active {
    color: #f12711;
  }
  
  .clear-btn {
    border: none;
    opacity: 0.6;
    outline: none;
    color: #fff;
    cursor: not-allowed;
    font-size: 13px;
    padding: 7px 13px;
    border-radius: 4px;
    letter-spacing: 0.3px;
    pointer-events: none;
    transition: transform 0.25s ease;
    background: linear-gradient(135deg, #f5af19 0%, #f12711 100%);
  }
  
  .clear-btn.active {
    opacity: 0.9;
    pointer-events: auto;
    cursor: pointer;
  }
  
  .clear-btn:active {
    transform: scale(0.93);
  }
  
  .task-box {
    margin-top: 20px;
    margin-right: 5px;
    padding: 0 20px 10px 25px;
  }
  
  .task-box.overflow {
    overflow-y: auto;
    max-height: 300px;
  }
  
  .task-box::-webkit-scrollbar {
    width: 5px;
  }
  
  .task-box::-webkit-scrollbar-track {
    background: #f12711;
    border-radius: 25px;
  }
  
  .task-box::-webkit-scrollbar-thumb {
    background: #e6e6e6;
    border-radius: 25px;
  }
  
  .task-box .task {
    list-style: none;
    font-size: 17px;
    margin-bottom: 18px;
    padding-bottom: 16px;
    align-items: flex-start;
    border-bottom: 1px solid #ccc;
  }
  
  .task-box .task:last-child {
    margin-bottom: 0;
    border-bottom: 0;
    padding-bottom: 0;
  }
  
  .task-box .task label {
    display: flex;
    align-items: flex-start;
  }
  
  .task label input {
    margin-top: 7px;
    accent-color: #f12711;
  }
  
  .task label p {
    user-select: none;
    margin-left: 12px;
    word-wrap: break-word;
  }
  
  .task label p.checked {
    text-decoration: line-through;
  }
  
  
  .task-box .task:last-child .task-menu {
    bottom: 0;
    transform-origin: bottom right;
  }
  
  .task-box .task:first-child .task-menu {
    bottom: -65px;
    transform-origin: top right;
  }
  
  .task-menu.show {
    transform: scale(1);
  }
  
  .task-menu li {
    height: 25px;
    font-size: 16px;
    margin-bottom: 2px;
    padding: 17px 15px;
    cursor: pointer;
    justify-content: flex-start;
  }
  
  .task-menu li:last-child {
    margin-bottom: 0;
  }
  
  
  @media (max-width: 400px) {
    body {
      padding: 0 10px;
    }
    .wrapper {
      padding: 20px 0;
    }
    .filters span {
      margin: 0 5px;
    }
    .task-input {
      padding: 0 20px;
    }
    .controls {
      padding: 18px 20px;
    }
    .task-box {
      margin-top: 20px;
      margin-right: 5px;
      padding: 0 15px 10px 20px;
    }
    .task label input {
      margin-top: 4px;
    }
  }
  
`;
