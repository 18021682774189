import React from "react";
import { LiveEditor, LiveError, LivePreview, LiveProvider } from "react-live";

const code2 = `<h1></h1>`;

function LiveCode({ code, className, noInline }) {
  return (
    <div className={className}>
      <LiveProvider
        code={code ? code : code2}
        noInline={noInline}
        mountStylesheet={false}
      >
        <div className="d-flex">
          <div className="w-50 bg-dark code-screen">
            <LiveEditor className="h-100" />
          </div>
          <div className="bg-light w-50">
            <p className="w-100 text-start text-white bg-osperb rounded-md py-2 px-3 text-dark">
              Result
            </p>
            <LiveError />
            <LivePreview />
          </div>
        </div>
      </LiveProvider>
    </div>
  );
}

export default LiveCode;
