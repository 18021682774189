import React, { useState } from "react";
import { Card, CardBody } from "reactstrap";

function Calculator() {
  const [result, setResult] = useState("");

  const char = ["+", "-", "/", "*", "%", "."];

  const lastChar = result?.slice(-1);
  const newSting = result?.slice(0, -1);

  const handleCalculatorInput = (e) => {
    if (char?.includes(e.target.name)) {
      if (result?.length >= 1) {
        if (char?.includes(lastChar)) {
          return setResult(newSting + e.target.name);
        } else {
          setResult(result + e.target.name);
        }
      }
    } else {
      setResult(result + e.target.name);
    }
  };

  const clearDisplay = () => {
    setResult("");
  };

  const delNumber = () => {
    setResult(result?.slice(0, -1));
  };

  const calculate = () => {
    if (result?.length >= 1) {
      setResult(eval(result).toString());
    }
  };

  return (
    <Card className=" ">
      <CardBody className="text-start ">
        <div className="calc-body">
          <div className="result-display">
            <h1 id="result-display">{result}</h1>
          </div>

          <div className="btn-wrap">
            <button
              onClick={clearDisplay}
              id="clear"
              className="btn btn-orange"
            >
              AC
            </button>
            <button onClick={delNumber} id="del" className="btn btn-orange">
              C
            </button>
            <button
              onClick={handleCalculatorInput}
              name="%"
              className="btn btn-orange"
            >
              %
            </button>
            <button
              onClick={handleCalculatorInput}
              name="/"
              className="btn btn-orange"
            >
              /
            </button>
            <button onClick={handleCalculatorInput} name="7" className="btn">
              7
            </button>
            <button onClick={handleCalculatorInput} name="8" className="btn">
              8
            </button>
            <button onClick={handleCalculatorInput} name="9" className="btn">
              9
            </button>
            <button
              onClick={handleCalculatorInput}
              name="*"
              className="btn btn-orange"
            >
              X
            </button>
            <button onClick={handleCalculatorInput} name="4" className="btn">
              4
            </button>
            <button onClick={handleCalculatorInput} name="5" className="btn">
              5
            </button>
            <button onClick={handleCalculatorInput} name="6" className="btn">
              6
            </button>
            <button
              onClick={handleCalculatorInput}
              name="-"
              className="btn btn-orange"
            >
              -
            </button>
            <button onClick={handleCalculatorInput} name="1" className="btn">
              1
            </button>
            <button onClick={handleCalculatorInput} name="2" className="btn">
              2
            </button>
            <button onClick={handleCalculatorInput} name="3" className="btn">
              3
            </button>
            <button
              onClick={handleCalculatorInput}
              name="+"
              className="btn btn-orange"
            >
              +
            </button>
            <button onClick={handleCalculatorInput} name="0" className="btn">
              0
            </button>
            <button onClick={handleCalculatorInput} name="." className="btn">
              .
            </button>
            <button onClick={calculate} className="btn btn-lg">
              =
            </button>
          </div>
        </div>
      </CardBody>
    </Card>
  );
}

export default Calculator;

export const calculatorCode = `import React, { useState } from "react";
import { Card, CardBody } from "reactstrap";

function Calculator() {
  const [result, setResult] = useState("");

  const char = ["+", "-", "/", "*", "%", "."];

  const lastChar = result?.slice(-1);
  const newSting = result?.slice(0, -1);

  const handleCalculatorInput = (e) => {
    if (char?.includes(e.target.name)) {
      if (result?.length >= 1) {
        if (char?.includes(lastChar)) {
          return setResult(newSting + e.target.name);
        } else {
          setResult(result + e.target.name);
        }
      }
    } else {
      setResult(result + e.target.name);
    }
  };

  const clearDisplay = () => {
    setResult("");
  };

  const delNumber = () => {
    setResult(result?.slice(0, -1));
  };

  const calculate = () => {
    if (result?.length >= 1) {
      setResult(eval(result).toString());
    }
  };

  return (
    <Card className=" ">
      <CardBody className="text-start ">
        <div className="calc-body">
          <div className="result-display">
            <h1 id="result-display">{result}</h1>
          </div>

          <div className="btn-wrap">
            <button
              onClick={clearDisplay}
              id="clear"
              className="btn btn-orange"
            >
              AC
            </button>
            <button onClick={delNumber} id="del" className="btn btn-orange">
              C
            </button>
            <button
              onClick={handleCalculatorInput}
              name="%"
              className="btn btn-orange"
            >
              %
            </button>
            <button
              onClick={handleCalculatorInput}
              name="/"
              className="btn btn-orange"
            >
              /
            </button>
            <button onClick={handleCalculatorInput} name="7" className="btn">
              7
            </button>
            <button onClick={handleCalculatorInput} name="8" className="btn">
              8
            </button>
            <button onClick={handleCalculatorInput} name="9" className="btn">
              9
            </button>
            <button
              onClick={handleCalculatorInput}
              name="*"
              className="btn btn-orange"
            >
              X
            </button>
            <button onClick={handleCalculatorInput} name="4" className="btn">
              4
            </button>
            <button onClick={handleCalculatorInput} name="5" className="btn">
              5
            </button>
            <button onClick={handleCalculatorInput} name="6" className="btn">
              6
            </button>
            <button
              onClick={handleCalculatorInput}
              name="-"
              className="btn btn-orange"
            >
              -
            </button>
            <button onClick={handleCalculatorInput} name="1" className="btn">
              1
            </button>
            <button onClick={handleCalculatorInput} name="2" className="btn">
              2
            </button>
            <button onClick={handleCalculatorInput} name="3" className="btn">
              3
            </button>
            <button
              onClick={handleCalculatorInput}
              name="+"
              className="btn btn-orange"
            >
              +
            </button>
            <button onClick={handleCalculatorInput} name="0" className="btn">
              0
            </button>
            <button onClick={handleCalculatorInput} name="." className="btn">
              .
            </button>
            <button onClick={calculate} className="btn btn-lg">
              =
            </button>
          </div>
        </div>
      </CardBody>
    </Card>
  );
}

export default Calculator;`;

export const calculatorCss = `
.calc-body {
  background-color: #243441;
  backdrop-filter: blur (10px);
  border-radius: 20px;
  padding: 40px 30px;
  width: 330px;
  margin: auto;
}
.result-display {
  color: white;
  background-color: #13181d25;
  height: 50px;
  padding: 20px;
  text-align: right;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: end;
}
.calc-display h3,
.result-display h1 {
  margin: 0;
  font-weight: 300;
}
.calc-display {
  display: flex;
  justify-content: end;
  align-items: center;
  background-color: #13181d25;
  color: white;
  height: 50px;
  padding: 0 15px;
  text-align: right;
  margin-top: 20px;
  border-radius: 10px;
  overflow: hidden;
}
.btn-wrap {
  margin-top: 10px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;
}
.btn {
  padding: 10px;
  height: 55px;
  width: 55px;
  font-size: medium;
  color: rgb(191, 191, 191) !important;
  background-color: #243441;
  border: none;
  outline: none;
  margin-top: 10px;
  border-radius: 10px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
  transition: all 0.1s ease 0s;
  cursor: pointer;
}
.btn:hover {
  background-color: #212b33 !important;
}
.btn:active {
  background-color: #13181d !important;
  box-shadow: none;
}
.btn-orange {
  color: #ed802e !important;
}
.btn-lg {
  width: 120px;
  background-color: #ed802e !important;
  color: #243441;
}
.btn-lg:hover {
  background-color: #e3741f !important;
}
`;
