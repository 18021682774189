import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import NavContainer from "./components/Navbar";
import ReactCourse from "./pages/react";
import PageNotFound from "./pages/PageNotFound";
import "./App.css";

function App() {
  return (
    <Router>
      <NavContainer />
      <Routes>
        <Route path="/react" element={<ReactCourse />} />
        <Route path="*" element={<PageNotFound />} />
        <Route path="/" element={<Navigate to={"/react"} replace />} />
      </Routes>
    </Router>
  );
}

export default App;
